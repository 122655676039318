






















import { Vue, Component } from 'vue-property-decorator';
import { mapState } from 'vuex';

interface Cell {
  selected: boolean;
  locked: boolean;
  error: boolean;
  value: number | '';
}

@Component(
  {
    name: 'Grid',
    computed: mapState([
      'size',
      'grid',
    ]),
  },
)
export default class Grid extends Vue {
  public grid!: Cell[][];

  public size!: number;

  setSelected(cell: Cell, x: number, y: number): void {
    if (cell.locked) return;
    this.$store.commit({ type: 'SET_CELL_SELECTED', x, y });
  }

  pickNumber(ev: { keyCode: number }): void {
    const value = parseInt(String.fromCharCode(ev.keyCode), 10);
    // If it was NaN, split out
    if (Number.isNaN(value)) return;
    console.log(value);
    this.$store.dispatch({ type: 'SET_CELL_VALUE', value, vm: this });
  }

  mounted(): void {
    window.addEventListener('keypress', this.pickNumber);
  }

  destroyed(): void {
    window.removeEventListener('keypress', this.pickNumber);
  }
}
