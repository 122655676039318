var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('table',[_c('tbody',_vm._l((_vm.grid),function(row,idy){return _c('tr',{key:idy,class:{
      bordered: ((idy + 1) % _vm.size) == 0,
    }},_vm._l((row),function(cell,idx){return _c('td',{key:idx,class:{
        locked: _vm.grid[idy][idx].locked,
        error: _vm.grid[idy][idx].error,
        selected: _vm.grid[idy][idx].selected,
        bordered: ((idx + 1) % _vm.size) == 0,
        },on:{"click":function($event){return _vm.setSelected(_vm.grid[idy][idx], idx, idy)}}},[_vm._v(" "+_vm._s(_vm.grid[idy][idx].value === 0 ? '' : _vm.grid[idy][idx].value))])}),0)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }