













































import { Vue, Component, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import Grid from '@/components/Grid.vue';

interface Cell {
  selected: boolean;
  locked: boolean;
  error: boolean;
  value: number | '';
}

@Component({
  name: 'Home',
  components: {
    Grid,
  },
  computed: mapState([
    'size',
    'difficulty',
    'grid',
    'solutionsCount',
  ]),
})
export default class Home extends Vue {
  public selectedSize = 3;

  public selectedDifficulty = 4;

  public readonly solutionsCount!: number;

  public readonly grid!: Cell[][];

  public optionsSize: { value: number, text: string }[] = [
    { value: 2, text: '2 x 2' },
    { value: 3, text: '3 x 3' },
    { value: 4, text: '4 x 4' },
    { value: 5, text: '5 x 5' },
  ];

  public optionsDifficulty: { value: number, text: string }[] = [
    { value: 0, text: 'CUSTOM' },
    { value: 1, text: 'EASY' },
    { value: 2, text: 'MEDIUM' },
    { value: 3, text: 'HARD' },
    { value: 4, text: 'EXPERT' },
    { value: 5, text: 'INSANE' },
    { value: 6, text: 'GOOD_LUCK' },
  ];

  checkSolutionsCount(size: number, grid: Cell[][]): void {
    this.$store.dispatch({
      type: 'GET_SOLUTIONS_COUNT', size, grid, vm: this,
    });
  }

  onOptionChange(): void {
    this.$store.dispatch({
      type: 'INIT_SUDOKU', size: this.selectedSize, difficulty: this.selectedDifficulty, vm: this,
    });
  }

  mounted(): void {
    if (localStorage.getItem('grid') && localStorage.getItem('gridSettings')) {
      try {
        const gridSettings: {
          size: number;
          difficulty: number;
        } = JSON.parse(localStorage.getItem('gridSettings') ?? `{size: "${this.selectedSize}",difficulty: "${this.selectedDifficulty}"}`);
        const grid: Cell[][] = JSON.parse(localStorage.getItem('grid') ?? '[]');
        this.selectedSize = gridSettings.size;
        this.selectedDifficulty = gridSettings.difficulty;
        this.$store.dispatch({
          type: 'INIT_SUDOKU', size: this.selectedSize, difficulty: this.selectedDifficulty, grid, vm: this,
        });
      } catch (error) {
        localStorage.removeItem('gridSettings');
        localStorage.removeItem('grid');
        if (error instanceof Error) {
          this.$bvToast.toast(`${error.name} | ${error.message}`, {
            title: 'Error',
            variant: 'danger',
            solid: true,
          });
          console.error(error);
        }
        this.$store.dispatch({
          type: 'INIT_SUDOKU', size: this.selectedSize, difficulty: this.selectedDifficulty, vm: this,
        });
      }
    } else {
      this.$store.dispatch({
        type: 'INIT_SUDOKU', size: this.selectedSize, difficulty: this.selectedDifficulty, vm: this,
      });
    }
  }
}
